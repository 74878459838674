'use client';

import { t } from '@lingui/macro';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { z } from 'zod';
const EmailLinkInvalidOrExpiredError = z.object({
  error: z.literal('unauthorized_client'),
  error_code: z.literal('401'),
  error_description: z.literal('Email+link+is+invalid+or+has+expired')
});
export const useEmailLinkError = () => {
  const [hashRecord, setHashRecord] = useState<Record<string, string> | undefined>(undefined);
  useEffect(() => {
    if (!hashRecord) {
      return;
    }
    const isEmailLinkInvalidOrExpiredError = EmailLinkInvalidOrExpiredError.safeParse(hashRecord);
    if (isEmailLinkInvalidOrExpiredError.success) {
      toast.error(t`Ce lien est invalide ou a expiré.`);
    }
  }, [hashRecord]);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const hashChangeHandler = () => {
      if (typeof window === 'undefined') {
        return undefined;
      }
      const {
        hash
      } = window.location;
      if (!hash) {
        setHashRecord(undefined);
        return;
      }
      const record = hash.replace('#', '').split('&').map(item => item.split('=')).reduce((prev, crt) => ({
        ...prev,
        [crt[0]]: crt[1]
      }), {} as Record<string, string>);
      if (Object.keys(record).length === 0) {
        setHashRecord(undefined);
        return;
      }
      setHashRecord(record);
    };
    hashChangeHandler();
    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  }, [setHashRecord]);
  return;
};