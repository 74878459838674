import { z } from 'zod';

export const ComingFromType = z.enum([
  'APP',
  'TOOLS',
  'AURA',
  'HELIO',
  'QUARK',
  'STORE',
  'PRISMO_IO',
  'DISC',
  'TEST_MOTIVATION',
  'LANDING_FORMATION',
  'LANDING_ECOLE',
  'LANDING_COACHING',
  'INVITATION',
  'PRISMOTHEQUE_INVITE',
  'COMPATIBILITY_INVITE',
  'ADMIN_INVITE',
  'TOOLER_INVITE',
  'COWORKER_INVITE',
  'PARTNER',
  'EMPLOI_STORE',
  'EMPLOI_BOX',
]);

export type ComingFromTypeT = z.infer<typeof ComingFromType>;
