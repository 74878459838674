'use client';

import 'client-only';
import { t } from '@lingui/macro';
import { useApi } from '@prismo-io/core';
import { env } from '@prismo-io/env';
import type { ApiClient, SWRArgs } from '@prismo-io/schemas';
import { type ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { z } from 'zod';
export const SendResetPasswordSchema = z.string().email().min(1, t`L'email est obligatoire.`);
export type SendResetPasswordSchemaT = z.infer<typeof SendResetPasswordSchema>;
const fetcher = async (client: ApiClient, email: SendResetPasswordSchemaT, searchParams?: URLSearchParams | ReadonlyURLSearchParams) => {
  const validEmail = SendResetPasswordSchema.parse(email);
  const redirectTo = new URL('/api/auth/reset-password', env.NEXT_PUBLIC_ONBOARDING_URL);
  if (searchParams) {
    searchParams.forEach((value, key) => {
      redirectTo.searchParams.append(key, value);
    });
  }
  const {
    error: resetError
  } = await client.auth.resetPasswordForEmail(validEmail, {
    redirectTo: redirectTo.toString()
  });
  if (resetError) {
    return true;
  }
  return true;
};
export function useSendResetPasswordForEmail() {
  const {
    client
  } = useApi();
  const searchParams = useSearchParams();
  return useSWRMutation('send-reset-password', (key, {
    arg
  }: SWRArgs<{
    email: SendResetPasswordSchemaT;
  }>) => {
    return toast.promise(fetcher(client, arg.email, searchParams), {
      loading: t`Envoi d'un email de réinitialisation du mot de passe.`,
      success: t`Vous allez recevoir un email de réinitialisation.`,
      error: t`Une erreur est survenue.`
    });
  });
}