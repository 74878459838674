import type { ReadonlyURLSearchParams } from 'next/navigation';

export const searchParamsToObject = (searchParams: ReadonlyURLSearchParams) =>
  [...searchParams.entries()].reduce(
    (
      prev: Record<string, string | string[]>,
      [key, value]
    ): Record<string, string | string[]> => {
      if (prev[key]) {
        if (Array.isArray(prev[key])) {
          return {
            ...prev,
            [key]: [...prev[key], value],
          } as Record<string, string | string[]>;
        }

        return {
          ...prev,
          [key]: [prev[key], value],
        } as Record<string, string | string[]>;
      }

      return {
        ...prev,
        [key]: value,
      } as Record<string, string | string[]>;
    },
    {} as Record<string, string | string[]>
  );
