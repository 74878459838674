'use client';

import En from '@/assets/icons/en.svg';
import Fr from '@/assets/icons/fr.svg';
import { useLingui } from '@lingui/react';
import { MenuItem, Select, type SelectChangeEvent } from '@mui/material';
import { LanguageCode, type LanguageCodeT } from '@prismo-io/schemas';
import Image from 'next/image';
import type { FC } from 'react';
import { useRouter } from '../hooks/use-router';
import type { Application } from '../types';
type Props = {
  app: Application;
};
export const LocaleSwitcher: FC<Props> = props => {
  const {
    app
  } = props;
  const router = useRouter();
  const {
    i18n
  } = useLingui();
  const changeLocale = (event: SelectChangeEvent) => {
    router.changeLocale({
      app,
      locale: event.target.value as LanguageCodeT
    });
  };
  return <Select value={i18n.locale} onChange={changeLocale} variant="outlined" size="small" sx={{
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }} data-sentry-element="Select" data-sentry-component="LocaleSwitcher" data-sentry-source-file="locale-switcher.tsx">
      <MenuItem value={LanguageCode.Enum.fr} data-sentry-element="MenuItem" data-sentry-source-file="locale-switcher.tsx">
        <Image src={Fr} alt="" width={22} height={30} data-sentry-element="Image" data-sentry-source-file="locale-switcher.tsx" />
      </MenuItem>
      <MenuItem value={LanguageCode.Enum.en} data-sentry-element="MenuItem" data-sentry-source-file="locale-switcher.tsx">
        <Image src={En} alt="" width={22} height={30} data-sentry-element="Image" data-sentry-source-file="locale-switcher.tsx" />
      </MenuItem>
    </Select>;
};