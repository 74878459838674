'use client';

import 'client-only';
import { useApi } from '@prismo-io/core';
import type { ApiClient } from '@prismo-io/schemas';
import useSWRMutation from 'swr/mutation';
const fetcher = async (client: ApiClient) => {
  await client.auth.signOut({
    scope: 'local'
  });
  return true;
};
export function useSignOut() {
  const {
    client
  } = useApi();
  return useSWRMutation('signout', () => fetcher(client));
}