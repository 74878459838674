'use client';

import { useLingui } from '@lingui/react';
import type { LanguageCodeT } from '@prismo-io/schemas';
import {
  useRouter as useNextRouter,
  useParams,
  usePathname,
  useSearchParams,
} from 'next/navigation';
import { useCallback } from 'react';
import type { Application } from '../types/application';
import type { AppPaths, LinkProps } from '../types/link';
import { decode } from '../utils/decode';
import { translate } from '../utils/translate';
import { useMaybeRouterCtx } from './router-provider';

type PushProps<App extends Application, Path extends AppPaths<App>> = LinkProps<
  App,
  Path
>;

type ChangeLocaleProps = {
  app: Application;
  locale: LanguageCodeT;
};

export const useRouter = () => {
  const router = useNextRouter();
  const { i18n } = useLingui();
  const pathname = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();

  const routerCtx = useMaybeRouterCtx();

  // const push = <App extends Application, Path extends AppPaths<App>>(
  //   props: PushProps<App, Path>
  // ) => {
  //   const { locale = i18n.locale, ...rest } = props;
  //   const href = translate<App, Path>({ locale, ...rest } as LinkProps<
  //     App,
  //     Path
  //   >);

  //   return router.push(href);
  // };

  const push = useCallback(
    async <App extends Application, Path extends AppPaths<App>>(
      props: PushProps<App, Path>
    ) => {
      const { locale = i18n.locale, ...rest } = props;
      const href = translate<App, Path>({ locale, ...rest } as LinkProps<
        App,
        Path
      >);

      if (
        typeof routerCtx !== 'undefined' &&
        typeof routerCtx.fn === 'function'
      ) {
        await routerCtx.fn();
      }

      return router.push(href);
    },
    [router, i18n.locale, routerCtx]
  );

  // const changeLocale = useCallback(
  //   (props: ChangeLocaleProps) => {
  //     const { app, locale } = props;
  //     const decoded = decode({
  //       app,
  //       locale: i18n.locale as LanguageCodeT,
  //       pathname,
  //       params,
  //     });

  //     return push({
  //       app,
  //       locale,
  //       path: decoded,
  //       params,
  //       queryParams,
  //
  //     } as any);
  //   },
  //   [push]
  // );

  const changeLocale = useCallback(
    async (props: ChangeLocaleProps) => {
      const { app, locale } = props;

      const queryParams = [...searchParams.entries()].reduce(
        (
          prev: Record<string, string | string[]>,
          [key, value]
        ): Record<string, string | string[]> => {
          if (prev[key]) {
            if (Array.isArray(prev[key])) {
              return {
                ...prev,
                [key]: [...prev[key], value],
              } as Record<string, string | string[]>;
            }

            return {
              ...prev,
              [key]: [prev[key], value],
            } as Record<string, string | string[]>;
          }

          return {
            ...prev,
            [key]: value,
          } as Record<string, string | string[]>;
        },
        {} as Record<string, string | string[]>
      );

      const decoded = decode({
        app,
        locale: i18n.locale as LanguageCodeT,
        pathname,
        params,
      } as any);

      const href = translate({
        app,
        locale,
        path: decoded,
        params,
        queryParams,
      } as any);

      if (
        typeof routerCtx !== 'undefined' &&
        typeof routerCtx.fn === 'function'
      ) {
        await routerCtx.fn();
      }

      router.push(href);
      return router.refresh();
    },
    [router, i18n.locale, pathname, params, searchParams, routerCtx]
  );

  return {
    ...router,
    push: push,
    changeLocale: changeLocale,
  };
};
