'use client';

import 'client-only';
import { t } from '@lingui/macro';
import { useApi } from '@prismo-io/core';
import { type ApiClient, ComingFromType, type SWRArgs } from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { z } from 'zod';
export const SignWithSSOSchema = z.object({
  email: z.string().email().trim().min(1, t`L'email est obligatoire.`),
  comingFrom: ComingFromType.nullish().optional(),
  campaign: z.string().trim().nullish().optional()
});
export type SignWithSSOSchemaT = z.infer<typeof SignWithSSOSchema>;
const fetcher = async (client: ApiClient, data: SignWithSSOSchemaT) => {
  const {
    email,
    comingFrom,
    campaign
  } = SignWithSSOSchema.parse(data);
  const {
    error: signError,
    data: success
  } = await client.auth.signInWithSSO({
    domain: email.split('@')[1]
  });
  if (signError) {
    throw signError;
  }
  return success;
};
export function useSignWithSSO() {
  const {
    client
  } = useApi();
  const usermaven = useUsermaven();
  return useSWRMutation('sign-with', async (key, {
    arg
  }: SWRArgs<SignWithSSOSchemaT>) => {
    const result = await toast.promise(fetcher(client, arg), {
      loading: t`Inscription en cours...`,
      success: t`Vous allez être redirigé !`,
      error: t`Une erreur est survenue, veuillez vérifier vos données.`
    });
    if (result) {
      usermaven.track('signed_sso', {
        from: arg.comingFrom || 'APP'
      });
      if (typeof window !== 'undefined') {
        window.location.href = result.url;
      }
    }
    return result;
  });
}